// project imports
import config from 'config';

// action - state management
import * as actionTypes from './auth.types';

export const initialState = {
    isLoggedIn: false,
    profile: null
};

// ==============================|| profile REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AUTH_LOGIN:
            console.log('LOGIN', action);
            return {
                ...state,
                isLoggedIn: true,
                profile: action.payload
            };
        case actionTypes.DEL_AUTH_LOGIN:
            return {
                ...state,
                isLoggedIn: false,
                profile: null
            };
        default:
            return state;
    }
};

export default authReducer;
