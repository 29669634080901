// action - state management
import * as actionTypes from './types';

// WARNING /!\
// This states should follow available props on DataGrid Component
const baseGridState = {
    page: 0,
    pageSize: 5,
    rowCount: 0,
    keyword: '',
    search: {},
    sortModel: [],
    rows: [],
    loading: false
};

export const initialState = {
    backendAccess: { ...baseGridState },
    merchandiser: { ...baseGridState },

    // Master Data
    account: { ...baseGridState },
    kategoriOutlet: { ...baseGridState },
    outlet: { ...baseGridState },
    area: { ...baseGridState },
    kota: { ...baseGridState },
    brand: { ...baseGridState },
    subBrand: { ...baseGridState },
    kategoriUkuran: { ...baseGridState },
    ukuran: { ...baseGridState },
    rasa: { ...baseGridState },
    kategoriKemasan: { ...baseGridState },
    produk: { ...baseGridState },
    produkKompetitor: { ...baseGridState },

    // Merchandiser
    kategoriDisplay: { ...baseGridState },
    absensi: { ...baseGridState },
    kunjunganOutlet: { ...baseGridState },
    kunjunganOutletGallery: { ...baseGridState },
    kunjunganOutletDisplayGallery: { ...baseGridState },
    pengumuman: { ...baseGridState }
};

// ==============================|| profile REDUCER ||============================== //

const gridReducer = (state = initialState, action) => {
    switch (action.type) {
        // Access
        case actionTypes.SET_GRID_BACKEND_ACCESS:
            return {
                ...state,
                backendAccess: action.payload
            };
        case actionTypes.SET_GRID_MERCHANDISER:
            return {
                ...state,
                merchandiser: action.payload
            };

        // Master
        case actionTypes.SET_GRID_ACCOUNT:
            return {
                ...state,
                account: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_OUTLET:
            return {
                ...state,
                kategoriOutlet: action.payload
            };
        case actionTypes.SET_GRID_OUTLET:
            return {
                ...state,
                outlet: action.payload
            };
        case actionTypes.SET_GRID_AREA:
            return {
                ...state,
                area: action.payload
            };
        case actionTypes.SET_GRID_KOTA:
            return {
                ...state,
                kota: action.payload
            };
        case actionTypes.SET_GRID_BRAND:
            return {
                ...state,
                brand: action.payload
            };
        case actionTypes.SET_GRID_SUB_BRAND:
            return {
                ...state,
                subBrand: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_UKURAN:
            return {
                ...state,
                kategoriUkuran: action.payload
            };
        case actionTypes.SET_GRID_UKURAN:
            return {
                ...state,
                ukuran: action.payload
            };
        case actionTypes.SET_GRID_RASA:
            return {
                ...state,
                rasa: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_KEMASAN:
            return {
                ...state,
                kategoriKemasan: action.payload
            };
        case actionTypes.SET_GRID_PRODUK:
            return {
                ...state,
                produk: action.payload
            };
        case actionTypes.SET_GRID_PRODUK_KOMPETITOR:
            return {
                ...state,
                produkKompetitor: action.payload
            };
        // Merchandiser
        case actionTypes.SET_GRID_KATEGORI_DISPLAY:
            return {
                ...state,
                kategoriDisplay: action.payload
            };
        case actionTypes.SET_GRID_ABSENSI:
            return {
                ...state,
                absensi: action.payload
            };
        case actionTypes.SET_GRID_KUNJUNGAN_OUTLET:
            return {
                ...state,
                kunjunganOutlet: action.payload
            };
        case actionTypes.SET_GRID_KUNJUNGAN_OUTLET_GALLERY:
            return {
                ...state,
                kunjunganOutletGallery: action.payload
            };
        case actionTypes.SET_GRID_KUNJUNGAN_OUTLET_DISPLAY_GALLERY:
            return {
                ...state,
                kunjunganOutletDisplayGallery: action.payload
            };
        default:
            return state;
    }
};

export default gridReducer;
