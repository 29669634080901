import axios from 'axios';

const { REACT_APP_CORE_SOA_URL, REACT_APP_SERVICE_URL } = process.env;

// --------------------------------
// Framework & Access
// --------------------------------
// Authentication
export const loginWithEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/session`, params);
export const loginWithPhone = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone/session`, params);
export const registerWithEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email`, params);
export const activateEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/${params.token}/activate`, params);
export const postRegisterPhone = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone`, params);
export const postOtp = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone/${params.no_handphone}/${params.action}`, params);
export const postResetPassword = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/reset_password`, params);
export const postConfirmResetPassword = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/${params.token}/confirm_reset_password`, params);
export const postExchangeJwt = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/exchange_jwt`, params);

// Profile
export const getProfileList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/profile`, { params });
export const getProfile = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params}`);
export const putProfile = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params.profile_id}`, params);
export const putAvatarProfile = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/image_avatar`, params);
export const getDashboardProfile = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/composites/profile/me/dashboard`, params);

// Auth Email
export const putAuthEmail = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params.profile_id}/auth_email/${params.auth_email_id}`, params);

// Backend Access
export const getBackendAccessList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/backend_access`, { params });
export const getBackendAccess = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${params}`);
export const postBackendAccess = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/backend_access`, params);
export const putBackendAccess = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${params.backend_access_id}`, params);
export const deleteBackendAccess = (backendAccessId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${backendAccessId}`);

// Merchandiser
export const getMerchandiserList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/merchandiser`, { params });
export const getMerchandiser = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/merchandiser/${params}`);
export const postMerchandiser = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/merchandiser`, params);
export const putMerchandiser = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/merchandiser/${params.merchandiser_id}`, params);
export const deleteMerchandiser = (merchandiserId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/merchandiser/${merchandiserId}`);

// --------------------------------
// Dashboard
// --------------------------------
export const getDashboard = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/dashboard/standard`, { params });
export const getDashboardMerchandiser = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/dashboard/merchandiser`, { params });
export const getDashboardAbsensi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/dashboard/absensi`, { params });
export const getChartProposalSummary = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/chart/proposal_summary`, { params });
export const getNotifikasi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/notifikasi`, { params });
export const putNotifikasi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/notifikasi`, params);

// --------------------------------
// CRUD
// --------------------------------
// Area
export const getAreaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/area`, { params });
export const getArea = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/area/${params}`);
export const postArea = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/area`, params);
export const putArea = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/area/${params.area_id}`, params);
export const deleteArea = (areaId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/area/${areaId}`);

// Kota
export const getKotaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kota`, { params });
export const getKota = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kota/${params}`);
export const postKota = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kota`, params);
export const putKota = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kota/${params.kota_id}`, params);
export const deleteKota = (kotaId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kota/${kotaId}`);

// Brand
export const getBrandList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/brand`, { params });
export const getBrand = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/brand/${params}`);
export const postBrand = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/brand`, params);
export const putBrand = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/brand/${params.brand_id}`, params);
export const deleteBrand = (brandId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/brand/${brandId}`);

// Sub Brand
export const getSubBrandList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand`, { params });
export const getSubBrand = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${params}`);
export const postSubBrand = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand`, params);
export const putSubBrand = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${params.sub_brand_id}`, params);
export const deleteSubBrand = (subBrandId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${subBrandId}`);

// Rasa
export const getRasaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/rasa`, { params });
export const getRasa = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${params}`);
export const postRasa = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/rasa`, params);
export const putRasa = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${params.rasa_id}`, params);
export const deleteRasa = (rasaId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${rasaId}`);

// Kategori Ukuran
export const getKategoriUkuranList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran`, { params });
export const getKategoriUkuran = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${params}`);
export const postKategoriUkuran = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran`, params);
export const putKategoriUkuran = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${params.kategori_ukuran_id}`, params);
export const deleteKategoriUkuran = (kategoriUkuranId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${kategoriUkuranId}`);

// Ukuran
export const getUkuranList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/ukuran`, { params });
export const getUkuran = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${params}`);
export const postUkuran = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/ukuran`, params);
export const putUkuran = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${params.ukuran_id}`, params);
export const deleteUkuran = (ukuranId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${ukuranId}`);

// Kategori Kemasan
export const getKategoriKemasanList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_kemasan`, { params });
export const getKategoriKemasan = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_kemasan/${params}`);
export const postKategoriKemasan = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_kemasan`, params);
export const putKategoriKemasan = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_kemasan/${params.kategori_kemasan_id}`, params);
export const deleteKategoriKemasan = (kategoriKemasanId) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_kemasan/${kategoriKemasanId}`);

// Produk
export const getProdukList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk`, { params });
export const getProduk = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk/${params}`);
export const postProduk = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/produk`, params);
export const putProduk = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/produk/${params.produk_id}`, params);
export const deleteProduk = (produkId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/produk/${produkId}`);

// Produk Kompetitor
export const getProdukKompetitorList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk_kompetitor`, { params });
export const getProdukKompetitor = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk_kompetitor/${params}`);
export const postProdukKompetitor = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/produk_kompetitor`, params);
export const putProdukKompetitor = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/produk_kompetitor/${params.produk_kompetitor_id}`, params);
export const deleteProdukKompetitor = (produkKompetitorId) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/produk_kompetitor/${produkKompetitorId}`);

// Account
export const getAccountList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/account`, { params });
export const getAccount = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/account/${params}`);
export const postAccount = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/account`, params);
export const putAccount = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/account/${params.account_id}`, params);
export const deleteAccount = (accountId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/account/${accountId}`);

// Outlet
export const getOutletList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/outlet`, { params });
export const getOutlet = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/outlet/${params}`);
export const postOutlet = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/outlet`, params);
export const putOutlet = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/outlet/${params.outlet_id}`, params);
export const deleteOutlet = (outletId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/outlet/${outletId}`);

// Kategori Outlet
export const getKategoriOutletList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_outlet`, { params });
export const getKategoriOutlet = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_outlet/${params}`);
export const postKategoriOutlet = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_outlet`, params);
export const putKategoriOutlet = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_outlet/${params.kategori_outlet_id}`, params);
export const deleteKategoriOutlet = (kategoriOutletId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_outlet/${kategoriOutletId}`);

// --------------------------------
// Merchandiser
// --------------------------------

// Kategori Display
export const getKategoriDisplayList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_display`, { params });
export const getKategoriDisplay = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_display/${params}`);
export const postKategoriDisplay = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_display`, params);
export const putKategoriDisplay = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_display/${params.kategori_display_id}`, params);
export const deleteKategoriDisplay = (kategoriDisplayId) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_display/${kategoriDisplayId}`);

// Absensi
export const getAbsensiList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/absensi`, { params });
export const getAbsensi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/absensi/${params}`);
export const postAbsensi = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/absensi`, params);
export const putAbsensi = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/absensi/${params.absensi_id}`, params);
export const deleteAbsensi = (absensiId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/absensi/${absensiId}`);

// Kunjungan Outlet
export const getKunjunganOutletFilter = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/filter/kunjungan_outlet`);
export const getKunjunganOutletList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet`, { params });
export const getKunjunganOutlet = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet/${params}`);
export const postKunjunganOutlet = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet`, params);
export const postKunjunganOutletDownload = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet_download`, params);
export const putKunjunganOutlet = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet/${params.kunjungan_outlet_id}`, params);
export const deleteKunjunganOutlet = (kunjunganOutletId) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet/${kunjunganOutletId}`);

// Kunjungan Outlet Display
export const getKunjunganOutletDisplayGallery = (params) =>
    axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet_display_gallery`, { params });
export const postKunjunganOutletDisplayDownload = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kunjungan_outlet_display_download`, params);

// --------------------------------
// Reporting
// --------------------------------
export const getReportCategoryList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category`, params);
export const getReportList = (params) =>
    axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report`, params);
export const getReportParameter = (params) =>
    axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/parameter`, params);
export const postReportExecute = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute`, params);
export const postReportExecutePdf = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute/pdf`, params, {
        responseType: 'arraybuffer'
    });
export const postReportExecuteXlsx = (params) =>
    axios.post(
        `${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute/xlsx`,
        params,
        {
            responseType: 'arraybuffer'
        }
    );
