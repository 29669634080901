import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';
import RequireAuth from '../RequireAuth';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardMerchandiser = Loadable(lazy(() => import('views/dashboard/DashboardMerchandiser')));
const DashboardAbsensi = Loadable(lazy(() => import('views/dashboard/DashboardAbsensi')));

// system routing
const ProfileSettings = Loadable(lazy(() => import('views/system/ProfileSettings')));
const ChangePassword = Loadable(lazy(() => import('views/system/ChangePassword')));
const ReportSwitchboard = Loadable(lazy(() => import('views/system/ReportSwitchboard')));

// master routing
const AreaList = Loadable(lazy(() => import('views/master/area/AreaList')));
const AreaForm = Loadable(lazy(() => import('views/master/area/AreaForm')));
const KotaList = Loadable(lazy(() => import('views/master/kota/KotaList')));
const KotaForm = Loadable(lazy(() => import('views/master/kota/KotaForm')));
const AccountList = Loadable(lazy(() => import('views/master/account/AccountList')));
const AccountForm = Loadable(lazy(() => import('views/master/account/AccountForm')));
const KategoriOutletList = Loadable(lazy(() => import('views/master/kategori-outlet/KategoriOutletList')));
const KategoriOutletForm = Loadable(lazy(() => import('views/master/kategori-outlet/KategoriOutletForm')));
const OutletList = Loadable(lazy(() => import('views/master/outlet/OutletList')));
const OutletForm = Loadable(lazy(() => import('views/master/outlet/OutletForm')));
const RasaList = Loadable(lazy(() => import('views/master/rasa/RasaList')));
const RasaForm = Loadable(lazy(() => import('views/master/rasa/RasaForm')));
const BrandList = Loadable(lazy(() => import('views/master/brand/BrandList')));
const BrandForm = Loadable(lazy(() => import('views/master/brand/BrandForm')));
const SubBrandList = Loadable(lazy(() => import('views/master/sub-brand/SubBrandList')));
const SubBrandForm = Loadable(lazy(() => import('views/master/sub-brand/SubBrandForm')));
const KategoriUkuranList = Loadable(lazy(() => import('views/master/kategori-ukuran/KategoriUkuranList')));
const KategoriUkuranForm = Loadable(lazy(() => import('views/master/kategori-ukuran/KategoriUkuranForm')));
const UkuranList = Loadable(lazy(() => import('views/master/ukuran/UkuranList')));
const UkuranForm = Loadable(lazy(() => import('views/master/ukuran/UkuranForm')));
const ProdukList = Loadable(lazy(() => import('views/master/produk/ProdukList')));
const ProdukForm = Loadable(lazy(() => import('views/master/produk/ProdukForm')));
const ProdukKompetitorList = Loadable(lazy(() => import('views/master/produk-kompetitor/ProdukKompetitorList')));
const ProdukKompetitorForm = Loadable(lazy(() => import('views/master/produk-kompetitor/ProdukKompetitorForm')));

const KategoriKemasanList = Loadable(lazy(() => import('views/master/kategori-kemasan/KategoriKemasanList')));
const KategoriKemasanForm = Loadable(lazy(() => import('views/master/kategori-kemasan/KategoriKemasanForm')));

const KategoriDisplayList = Loadable(lazy(() => import('views/master/kategori-display/KategoriDisplayList')));
const KategoriDisplayForm = Loadable(lazy(() => import('views/master/kategori-display/KategoriDisplayForm')));

const BackendAccessList = Loadable(lazy(() => import('views/access/backend-access/BackendAccessList')));
const BackendAccessForm = Loadable(lazy(() => import('views/access/backend-access/BackendAccessForm')));

const MerchandiserList = Loadable(lazy(() => import('views/access/merchandiser/MerchandiserList')));
const MerchandiserForm = Loadable(lazy(() => import('views/access/merchandiser/MerchandiserForm')));
const MerchandiserView = Loadable(lazy(() => import('views/access/merchandiser/MerchandiserView')));

// Merchandiser Core System
const AbsensiList = Loadable(lazy(() => import('views/merchandiser/absensi/AbsensiList')));
const AbsensiGallery = Loadable(lazy(() => import('views/merchandiser/absensi/AbsensiGallery')));
const AbsensiForm = Loadable(lazy(() => import('views/merchandiser/absensi/AbsensiForm')));
const AbsensiView = Loadable(lazy(() => import('views/merchandiser/absensi/AbsensiView')));

const KunjunganOutletList = Loadable(lazy(() => import('views/merchandiser/kunjungan-outlet/KunjunganOutletList')));
const KunjunganOutletGallery = Loadable(lazy(() => import('views/merchandiser/kunjungan-outlet/KunjunganOutletGallery')));
const KunjunganOutletDisplayGallery = Loadable(lazy(() => import('views/merchandiser/kunjungan-outlet/KunjunganOutletDisplayGallery')));
const KunjunganOutletForm = Loadable(lazy(() => import('views/merchandiser/kunjungan-outlet/KunjunganOutletForm')));
const KunjunganOutletView = Loadable(lazy(() => import('views/merchandiser/kunjungan-outlet/KunjunganOutletView')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        /*
        {
            path: '/',
            element: <DashboardDefault />
        },
        */
        {
            path: '/',
            element: <DashboardMerchandiser />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard-merchandiser',
            element: <DashboardMerchandiser />
        },
        {
            path: '/dashboard-absensi',
            element: <DashboardAbsensi />
        },
        // Settings
        {
            path: '/system/profile-settings',
            element: <ProfileSettings />
        },
        {
            path: '/system/change-password',
            element: <ChangePassword />
        },
        {
            path: '/master/area/list',
            element: <AreaList />
        },
        {
            path: '/master/area/add',
            element: <AreaForm />
        },
        {
            path: '/master/area/edit/:area_id',
            element: <AreaForm />
        },
        {
            path: '/master/kota/list',
            element: <KotaList />
        },
        {
            path: '/master/kota/add',
            element: <KotaForm />
        },
        {
            path: '/master/kota/edit/:kota_id',
            element: <KotaForm />
        },
        {
            path: '/master/account/list',
            element: <AccountList />
        },
        {
            path: '/master/account/add',
            element: <AccountForm />
        },
        {
            path: '/master/account/edit/:account_id',
            element: <AccountForm />
        },
        {
            path: '/master/kategori-outlet/list',
            element: <KategoriOutletList />
        },
        {
            path: '/master/kategori-outlet/add',
            element: <KategoriOutletForm />
        },
        {
            path: '/master/kategori-outlet/edit/:kategori_outlet_id',
            element: <KategoriOutletForm />
        },
        {
            path: '/master/outlet/edit/:outlet_id',
            element: <OutletForm />
        },
        {
            path: '/master/outlet/list',
            element: <OutletList />
        },
        {
            path: '/master/outlet/add',
            element: <OutletForm />
        },
        {
            path: '/master/outlet/edit/:outlet_id',
            element: <OutletForm />
        },
        {
            path: '/master/brand/list',
            element: <BrandList />
        },
        {
            path: '/master/brand/add',
            element: <BrandForm />
        },
        {
            path: '/master/brand/edit/:brand_id',
            element: <BrandForm />
        },

        {
            path: '/master/sub-brand/list',
            element: <SubBrandList />
        },
        {
            path: '/master/sub-brand/add',
            element: <SubBrandForm />
        },
        {
            path: '/master/sub-brand/edit/:sub_brand_id',
            element: <SubBrandForm />
        },

        {
            path: '/master/kategori-ukuran/list',
            element: <KategoriUkuranList />
        },
        {
            path: '/master/kategori-ukuran/add',
            element: <KategoriUkuranForm />
        },
        {
            path: '/master/kategori-ukuran/edit/:kategori_ukuran_id',
            element: <KategoriUkuranForm />
        },
        {
            path: '/master/ukuran/list',
            element: <UkuranList />
        },
        {
            path: '/master/ukuran/add',
            element: <UkuranForm />
        },
        {
            path: '/master/ukuran/edit/:ukuran_id',
            element: <UkuranForm />
        },

        {
            path: '/master/rasa/list',
            element: <RasaList />
        },
        {
            path: '/master/rasa/add',
            element: <RasaForm />
        },
        {
            path: '/master/rasa/edit/:rasa_id',
            element: <RasaForm />
        },
        {
            path: '/master/produk/edit/:produk_id',
            element: <ProdukForm />
        },

        {
            path: '/master/produk/list',
            element: <ProdukList />
        },
        {
            path: '/master/produk/add',
            element: <ProdukForm />
        },
        {
            path: '/master/produk/edit/:produk_id',
            element: <ProdukForm />
        },
        {
            path: '/master/produk-kompetitor/list',
            element: <ProdukKompetitorList />
        },
        {
            path: '/master/produk-kompetitor/add',
            element: <ProdukKompetitorForm />
        },
        {
            path: '/master/produk-kompetitor/edit/:produk_kompetitor_id',
            element: <ProdukKompetitorForm />
        },

        {
            path: '/master/kategori-kemasan/list',
            element: <KategoriKemasanList />
        },
        {
            path: '/master/kategori-kemasan/add',
            element: <KategoriKemasanForm />
        },
        {
            path: '/master/kategori-kemasan/edit/:kategori_kemasan_id',
            element: <KategoriKemasanForm />
        },

        {
            path: '/master/kategori-display/list',
            element: <KategoriDisplayList />
        },
        {
            path: '/master/kategori-display/add',
            element: <KategoriDisplayForm />
        },
        {
            path: '/master/kategori-display/edit/:kategori_display_id',
            element: <KategoriDisplayForm />
        },

        {
            path: '/access/backend-access/list',
            element: <BackendAccessList />
        },
        {
            path: '/access/backend-access/add',
            element: <BackendAccessForm />
        },
        {
            path: '/access/backend-access/edit/:backend_access_id',
            element: <BackendAccessForm />
        },
        {
            path: '/access/merchandiser/list',
            element: <MerchandiserList />
        },
        {
            path: '/access/merchandiser/add',
            element: <MerchandiserForm />
        },
        {
            path: '/access/merchandiser/edit/:merchandiser_id',
            element: <MerchandiserForm />
        },
        {
            path: '/access/merchandiser/view/:merchandiser_id',
            element: <MerchandiserView />
        },

        // Merchandiser Core
        // ----------------
        {
            path: '/merchandiser/absensi/list',
            element: <AbsensiList />
        },
        {
            path: '/merchandiser/absensi/gallery',
            element: <AbsensiGallery />
        },
        {
            path: '/merchandiser/absensi/add',
            element: <AbsensiForm />
        },
        {
            path: '/merchandiser/absensi/edit/:absensi_id',
            element: <AbsensiForm />
        },
        {
            path: '/merchandiser/absensi/view/:absensi_id',
            element: <AbsensiView />
        },
        {
            path: '/merchandiser/kunjungan-outlet/list',
            element: <KunjunganOutletList />
        },
        {
            path: '/merchandiser/kunjungan-outlet/gallery',
            element: <KunjunganOutletGallery />
        },
        {
            path: '/merchandiser/kunjungan-outlet/gallery-display',
            element: <KunjunganOutletDisplayGallery />
        },
        {
            path: '/merchandiser/kunjungan-outlet/add',
            element: <KunjunganOutletForm />
        },
        {
            path: '/merchandiser/kunjungan-outlet/edit/:kunjungan_outlet_id',
            element: <KunjunganOutletForm />
        },
        {
            path: '/merchandiser/kunjungan-outlet/view/:kunjungan_outlet_id',
            element: <KunjunganOutletView />
        },

        // Reporting
        // ----------------
        {
            path: '/system/report-switchboard',
            element: <ReportSwitchboard />
        },

        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
