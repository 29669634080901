import { combineReducers } from 'redux';

// reducer import
import storageAwareReducer from './storageAwareReducer';
import customizationReducer from './customizationReducer';
import authReducer from './auth/auth.reducers';
import gridReducer from './grid/reducers';
import uiReducer from './ui/reducers';
import dashboardReducer from './dashboard/reducers';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    storage: storageAwareReducer,
    customization: customizationReducer,
    auth: authReducer,
    grid: gridReducer,
    ui: uiReducer,
    dashboard: dashboardReducer
});

export default reducer;
