import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from './reducer';
import { authMiddleware } from 'store/auth/auth.actions';
import createEngine from 'redux-storage-engine-localstorage';

// ==============================|| REDUX - MAIN STORE ||============================== //

const createStoreWithMiddleware = applyMiddleware(thunk, authMiddleware)(createStore);
const store = createStoreWithMiddleware(combinedReducers);

export default store;
