import dashboard from './dashboard';
import admin from './admin';
import marketing from './marketing';
import proposal from './proposal';
import report from './report';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, admin, report]
};

export default menuItems;
