import axios from 'axios';
import { getUserToken } from 'store/auth/auth.actions';
import store from 'store';
import { SET_REFRESH_TOKEN } from 'store/auth/auth.types';

let subscribers = [];
let isRefreshing = false;

function subscribeTokenRefresh(cb) {
    subscribers.push(cb);
}

function onRefreshed() {
    subscribers.map((cb) => cb());
}

subscribers = [];

export default async () => {
    axios.interceptors.request.use(
        // To embbed request token
        async (config) => {
            const token = await getUserToken();

            if (token !== undefined && token !== null && token !== '') {
                config.headers.Authorization = `Bearer ${token}`;
            }

            config.headers['Content-Type'] = 'application/json';
            // set warning for debugging
            // console.warn(`${config.method}:${config.url}`);
            return config;
        },
        (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
        (resp) => resp,
        (error) => {
            const originalRequest = error.config;

            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    // Token expired error
                    if (!isRefreshing) {
                        isRefreshing = true;

                        store
                            .dispatch({ type: SET_REFRESH_TOKEN })
                            .then(() => {
                                subscribers = [];
                                isRefreshing = false;
                            })
                            .catch((error) => {
                                console.warn('error', error);
                            });
                    }

                    const requestSubscribers = new Promise((resolve) => {
                        subscribeTokenRefresh(() => {
                            resolve(axios(originalRequest));
                        });
                    });

                    onRefreshed();

                    return requestSubscribers;
                }

                // Only for write type of methods
                if (error.response.status.toString().charAt(0) === '4' || error.response.status.toString().charAt(0) === '5') {
                    // Err 4xx or 5xx
                    console.log('THE SNACKBAR 4xx 5xx: ', {
                        text: error?.response?.data?.status_msg,
                        duration: 'Snackbar.LENGTH_LONG',
                        backgroundColor: 'Colors.error'
                    });

                    return Promise.reject(error.response.data);
                }

                // Other than 4xx or 5xx
                console.log('THE SNACKBAR OTHER: ', {
                    text: 'Ooops. Something happened while submitting data. Please restart the app',
                    duration: 'Snackbar.LENGTH_LONG',
                    backgroundColor: 'Colors.error'
                });

                return Promise.reject(new Error('Ooops. Something happened while submitting data. Please restart the app'));
            }

            return Promise.reject(error);
        }
    );
};
