// assets
import { IconKey, IconDatabase } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconDatabase
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const marketing = {
    id: 'admin',
    title: 'Marketing',
    caption: 'Pengelompokan Customer',
    type: 'group',
    children: [
        {
            id: 'access',
            title: 'Access',
            type: 'collapse',
            icon: icons.IconKey,

            children: [
                {
                    id: 'user',
                    title: 'User',
                    type: 'item',
                    url: '/master/login/login',
                    target: true
                },
                {
                    id: 'register3',
                    title: 'Cost Center',
                    type: 'item',
                    url: '/master/register/register3',
                    target: true
                }
            ]
        },
        {
            id: 'master',
            title: 'Master Data',
            type: 'collapse',
            icon: icons.IconDatabase,

            children: [
                {
                    id: 'brand',
                    title: 'Rasa',
                    type: 'item',
                    url: '/master/login/login',
                    target: true
                },
                {
                    id: 'ukuran',
                    title: 'Ukuran',
                    type: 'item',
                    url: '/master/register/register3',
                    target: true
                },
                {
                    id: 'ukuran',
                    title: 'Brand',
                    type: 'item',
                    url: '/master/register/register3',
                    target: true
                }
            ]
        }
    ]
};

export default marketing;
