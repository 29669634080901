import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, useRoutes, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

// routes
import MainRoutes from './route-groups/MainRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function PrivateRoutes() {
    return useRoutes([MainRoutes], config.basename);
}
