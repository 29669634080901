import {
    SET_UI_DIALOG,
    SET_UI_SEARCH,
    SET_UI_SEARCH_KEYWORD,
    SET_UI_SEARCH_DRAWER_OPEN,
    SET_UI_SEARCH_DRAWER_OPEN_TOGGLE,
    SET_UI_FULL_SCREEN_IMAGE_VIEWER,
    SET_UI_FULL_SCREEN_IMAGE_VIEWER_INDEX
} from './types';

export const actionOpenDialog = (title, message, onOk, onCancel) => async (dispatch) => {
    if (typeof onCancel === 'undefined') {
        const onCancel = null;
    }

    await dispatch({
        type: SET_UI_DIALOG,
        payload: { title, message, onOk, onCancel, open: true }
    });
};

export const actionCloseDialog = () => async (dispatch) => {
    await dispatch({
        type: SET_UI_DIALOG,
        payload: {
            open: false,
            title: '',
            message: '',
            onOk: null,
            onCancel: null
        }
    });
};

export const actionOpenFullScreenImageViewer = (images, index, detailRoutePrefix) => async (dispatch) => {
    await dispatch({
        type: SET_UI_FULL_SCREEN_IMAGE_VIEWER,
        payload: { images, index, open: true, detailRoutePrefix }
    });
};

export const actionSetIndexFullScreenImageViewer = (index) => async (dispatch) => {
    await dispatch({
        type: SET_UI_FULL_SCREEN_IMAGE_VIEWER_INDEX,
        payload: index
    });
};

export const actionCloseFullScreenImageViewer = () => async (dispatch) => {
    await dispatch({
        type: SET_UI_FULL_SCREEN_IMAGE_VIEWER,
        payload: {
            open: false,
            index: 0,
            images: []
        }
    });
};

export const actionConfigureSearch = (activeDataGrid, context) => async (dispatch) => {
    await dispatch({
        type: SET_UI_SEARCH,
        payload: {
            activeDataGrid,
            context,
            keyword: ''
        }
    });

    await dispatch({
        type: SET_UI_SEARCH_DRAWER_OPEN,
        payload: false
    });
};
export const actionResetSearch = (context) => async (dispatch) => {
    await dispatch({
        type: SET_UI_SEARCH,
        payload: {
            activeDataGrid: null,
            context: null,
            keyword: ''
        }
    });

    await dispatch({
        type: SET_UI_SEARCH_DRAWER_OPEN,
        payload: false
    });
};
export const actionSetSearchKeyword = (keyword) => async (dispatch) => {
    await dispatch({
        type: SET_UI_SEARCH_KEYWORD,
        payload: keyword
    });
};
export const actionSetSearchDrawerOpen = (open) => async (dispatch) => {
    await dispatch({
        type: SET_UI_SEARCH_DRAWER_OPEN,
        payload: open
    });
};
export const actionSetSearchDrawerOpenToggle = () => async (dispatch) => {
    await dispatch({
        type: SET_UI_SEARCH_DRAWER_OPEN_TOGGLE
    });
};
