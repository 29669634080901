import { useNavigate } from 'react-router';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto, IconSettings } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';

import StdRelativeDateTimeFormat from 'ui-component/data/StdRelativeDateTimeFormat';
import { initialName } from 'utils/helpers';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ setOpen, notifikasi }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    const handleClick = (row) => {
        console.log(row.lampiran);
        switch (row.jenis_lampiran) {
            case 'PROPOSAL':
                navigate(`/proposal/proposal/view/${row.lampiran}`);
                break;
            case 'KUNJUNGAN_OUTLET_EXPORT_CHECK_IN_OUT':
                window.location = row.lampiran;
                break;
            case 'KUNJUNGAN_OUTLET_EXPORT_DISPLAY':
                window.location = row.lampiran;
                break;
            case 'ABSENSI_EXPORT':
                window.location = row.lampiran;
                break;

            default:
                console.log('NO ACTION');
        }

        setOpen(false);
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifikasi.map((row) => (
                <>
                    <ListItemWrapper onClick={() => handleClick(row)}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar alt="System" icon={IconSettings} />
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography sx={{ fontWeight: row.dibaca ? 'normal' : 'bold' }}>{row.judul}</Typography>
                            </ListItemText>
                        </ListItem>
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12} sx={{ pb: 0 }}>
                                <Typography variant="subtitle2">{row.keterangan}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ pb: 1 }}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    <StdRelativeDateTimeFormat text={row.__created} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Chip label={row.event_type} sx={chipSuccessSX} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </>
            ))}
        </List>
    );
};

export default NotificationList;
