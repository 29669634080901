// Access Control
export const SET_GRID_BACKEND_ACCESS = 'SET_GRID_BACKEND_ACCESS';
export const SET_GRID_MERCHANDISER = 'SET_GRID_MERCHANDISER';

// General
export const SET_GRID_ACCOUNT = 'SET_GRID_ACCOUNT';
export const SET_GRID_KATEGORI_OUTLET = 'SET_GRID_KATEGORI_OUTLET';
export const SET_GRID_OUTLET = 'SET_GRID_OUTLET';
export const SET_GRID_AREA = 'SET_GRID_AREA';
export const SET_GRID_KOTA = 'SET_GRID_KOTA';
export const SET_GRID_BRAND = 'SET_GRID_BRAND';
export const SET_GRID_SUB_BRAND = 'SET_GRID_SUB_BRAND';
export const SET_GRID_KATEGORI_HARGA = 'SET_GRID_KATEGORI_HARGA';
export const SET_GRID_KATEGORI_UKURAN = 'SET_GRID_KATEGORI_UKURAN';
export const SET_GRID_RASA = 'SET_GRID_RASA';
export const SET_GRID_UKURAN = 'SET_GRID_UKURAN';
export const SET_GRID_KATEGORI_KEMASAN = 'SET_GRID_KATEGORI_KEMASAN';
export const SET_GRID_PRODUK = 'SET_GRID_PRODUK';
export const SET_GRID_PRODUK_KOMPETITOR = 'SET_GRID_PRODUK_KOMPETITOR';

// Merchandiser
export const SET_GRID_KATEGORI_DISPLAY = 'SET_GRID_KATEGORI_DISPLAY';
export const SET_GRID_ABSENSI = 'SET_GRID_ABSENSI';
export const SET_GRID_KUNJUNGAN_OUTLET = 'SET_GRID_KUNJUNGAN_OUTLET';
export const SET_GRID_KUNJUNGAN_OUTLET_GALLERY = 'SET_GRID_KUNJUNGAN_OUTLET_GALLERY';
export const SET_GRID_KUNJUNGAN_OUTLET_DISPLAY_GALLERY = 'SET_GRID_KUNJUNGAN_OUTLET_DISPLAY_GALLERY';
