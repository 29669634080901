import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import Lightbox from 'react-image-lightbox';

import { actionCloseFullScreenImageViewer, actionSetIndexFullScreenImageViewer } from 'store/ui/actions';
import { useDispatch, useSelector } from 'react-redux';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const FullScreenImageViewer = () => {
    const dispatch = useDispatch();
    const { open, images, index, detailRoutePrefix } = useSelector((state) => state.ui.fullScreenImageViewer);

    const handleClose = () => {
        dispatch(actionCloseFullScreenImageViewer());
    };

    const setPhotoIndex = (idx) => {
        dispatch(actionSetIndexFullScreenImageViewer(idx));
    };

    return (
        <>
            {open && (
                <Lightbox
                    reactModalStyle={{ overlay: { zIndex: 999999 } }}
                    imageTitle={images[index].title}
                    imageCaption={images[index].caption}
                    mainSrc={images[index].url}
                    nextSrc={images[(index + 1) % images.length].url}
                    prevSrc={images[(index + images.length - 1) % images.length].url}
                    imagePadding={60}
                    onCloseRequest={() => handleClose(false)}
                    onMovePrevRequest={() => setPhotoIndex((index + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((index + 1) % images.length)}
                    toolbarButtons={
                        detailRoutePrefix
                            ? [
                                  <Link to={`${detailRoutePrefix}/${images[index].ref}`} target="_blank">
                                      <Tooltip title="Lihat Detail" placement="top">
                                          <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label={`Lokasi: `}>
                                              <InfoIcon />
                                          </IconButton>
                                      </Tooltip>
                                  </Link>
                              ]
                            : []
                    }
                />
            )}
        </>
    );
};

export default FullScreenImageViewer;
