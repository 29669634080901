// assets
import { IconDashboard, IconMap2, IconAccessPoint } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        /*
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: IconDashboard,
            breadcrumbs: false
        },
        */
        {
            id: 'default-merchandiser',
            title: 'Dashboard Kunjungan',
            type: 'item',
            url: '/dashboard-merchandiser',
            icon: IconMap2,
            breadcrumbs: false
        },
        {
            id: 'default-absensi',
            title: 'Dashboard Absensi',
            type: 'item',
            url: '/dashboard-absensi',
            icon: IconAccessPoint,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
