import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserToken } from 'store/auth/auth.actions';

function RequireAuth({ children }) {
    const token = getUserToken();
    const location = useLocation();
    // Subscribe to auth when auth changed, this Routes also
    // refreshed
    const auth = useSelector((state) => state.auth);
    if (!token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export default RequireAuth;
