import { useNavigate } from 'react-router-dom';
import { getProfile, loginWithEmail, loginWithPhone, postExchangeJwt } from 'services/internal/core-soa';
import { SET_AUTH_LOGIN, DEL_AUTH_LOGIN, KEY_ACCESSTOKEN, SET_AUTH_IS_LOGGED_IN, KEY_REFRESH_TOKEN } from './auth.types';

export const getUserToken = () => {
    const token = localStorage.getItem(KEY_ACCESSTOKEN);
    return token;
};

export const getRefreshToken = () => {
    const refreshToken = localStorage.getItem(KEY_REFRESH_TOKEN);
    return refreshToken;
};

export const setAuthProfile = (profile) => {
    localStorage.setItem('AUTH_PROFILE', JSON.stringify(profile));
};
export const getAuthProfile = () => {
    const json = localStorage.getItem('AUTH_PROFILE');
    return json ? JSON.parse(json) : null;
};

export const delUserToken = () => {
    localStorage.removeItem(KEY_ACCESSTOKEN);
};

export const delAuthProfile = () => {
    localStorage.removeItem(KEY_ACCESSTOKEN);
    localStorage.removeItem(KEY_REFRESH_TOKEN);
    localStorage.removeItem('AUTH_PROFILE');
};

export const actionSetUserLogin = (params) => async (dispatch) => {
    localStorage.setItem(KEY_ACCESSTOKEN, params.data.access_token);
    localStorage.setItem(KEY_REFRESH_TOKEN, params.data.refresh_token);
    localStorage.setItem('AUTH_PROFILE', JSON.stringify(params.data.profile));

    dispatch({
        type: SET_AUTH_LOGIN,
        payload: params.data.token
    });
};

export const actionAuthLoginWithEmail = (params) => async (dispatch) => {
    const newParams = {
        ...params
    };
    const res = await loginWithEmail(newParams);
    await dispatch(actionSetUserLogin(res));
};

export const actionAuthLoginWithPhone = (params) => async (dispatch) => {
    await dispatch(loginWithPhone(params));
};

export const authLogout = () => async (dispatch) => {
    delAuthProfile();
    dispatch({
        type: DEL_AUTH_LOGIN
    });
};

export const actionPostRefreshToken = async (dispatch) => {
    try {
        const token = await getUserToken();
        if (!token) {
            return;
        }

        delUserToken();

        const refreshToken = await localStorage.getItem(KEY_REFRESH_TOKEN);
        const params = { refresh_token: refreshToken };
        const res = await postExchangeJwt(params);

        dispatch(actionSetUserLogin(res));

        const payload = {
            isUserSign: true,
            currentUser: res.data
        };

        dispatch({
            type: SET_AUTH_LOGIN,
            payload
        });
    } catch (error) {
        await dispatch(authLogout());
        /**
         * TODO: Reset Navigation Stack
         * navigation.reset({
         *   index: 0,
         *   routes: [{ name: 'Auth' }]
         * });
         */

        throw error;
    }
};

export const authMiddleware = (store) => (next) => async (action) => {
    if (action.type === 'SET_REFRESH_TOKEN') {
        try {
            await actionPostRefreshToken(store.dispatch);
        } catch (error) {
            console.warn('authMiddleware->error', error);
        }

        next(action);
    } else {
        next(action);
    }
};
