import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { actionCloseDialog } from 'store/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const ResponsiveDialog = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { open, title, message, onOk, onCancel } = useSelector((state) => state.ui.dialog);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleOk = () => {
        dispatch(actionCloseDialog());
        if (onOk) {
            onOk();
        }
    };

    const handleCancel = () => {
        dispatch(actionCloseDialog());
        onCancel();
    };

    const handleClose = () => {
        dispatch(actionCloseDialog());
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title" sx={{ p: 3 }}>
                <Typography variant="h4">{title}</Typography>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>

            {onCancel ? (
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={handleOk} variant="contained" disableElevation autoFocus>
                        Ya
                    </Button>
                    <Button onClick={handleCancel} variant="outlined">
                        Tidak
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={handleOk} variant="contained" disableElevation autoFocus>
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ResponsiveDialog;
