// assets
import { IconKey, IconDatabase, IconShoppingCart, IconBusinessplan, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconDatabase,
    IconShoppingCart,
    IconBusinessplan,
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: 'Administration',
    caption: 'Pengaturan Sistem',
    type: 'group',
    roles: ['ADMIN', 'FINANCE', 'ACCOUNTING'],
    children: [
        {
            id: 'access',
            title: 'Access',
            type: 'collapse',
            icon: icons.IconKey,
            roles: ['ADMIN'],
            children: [
                {
                    id: 'backend-access',
                    title: 'Backend Access',
                    type: 'item',
                    url: '/access/backend-access/list',
                    target: false
                },
                {
                    id: 'user',
                    title: 'Merchandiser',
                    type: 'item',
                    url: '/access/merchandiser/list',
                    target: false
                }
            ]
        },
        {
            id: 'master',
            title: 'Master Data',
            type: 'collapse',
            icon: icons.IconDatabase,
            roles: ['ADMIN'],
            children: [
                {
                    id: 'account',
                    title: 'Account',
                    type: 'item',
                    url: '/master/account/list'
                },
                {
                    id: 'area',
                    title: 'Area',
                    type: 'item',
                    url: '/master/area/list'
                },
                {
                    id: 'brand',
                    title: 'Brand',
                    type: 'item',
                    url: '/master/brand/list'
                },
                {
                    id: 'kategori-display',
                    title: 'Kategori Display',
                    type: 'item',
                    url: '/master/kategori-display/list'
                },
                {
                    id: 'kategori-kemasan',
                    title: 'Kategori Kemasan',
                    type: 'item',
                    url: '/master/kategori-kemasan/list'
                },
                {
                    id: 'kategori-outlet',
                    title: 'Kategori Outlet',
                    type: 'item',
                    url: '/master/kategori-outlet/list'
                },
                {
                    id: 'kategori-ukuran',
                    title: 'Kategori Ukuran',
                    type: 'item',
                    url: '/master/kategori-ukuran/list'
                },
                {
                    id: 'kota',
                    title: 'Kota',
                    type: 'item',
                    url: '/master/kota/list'
                },
                {
                    id: 'outlet',
                    title: 'Outlet',
                    type: 'item',
                    url: '/master/outlet/list'
                },
                {
                    id: 'produk',
                    title: 'Produk',
                    type: 'item',
                    url: '/master/produk/list'
                },
                {
                    id: 'produk-kompetitor',
                    title: 'Produk Kompetitor',
                    type: 'item',
                    url: '/master/produk-kompetitor/list'
                },
                {
                    id: 'rasa',
                    title: 'Rasa',
                    type: 'item',
                    url: '/master/rasa/list'
                },
                {
                    id: 'sub-brand',
                    title: 'Sub Brand',
                    type: 'item',
                    url: '/master/sub-brand/list'
                },
                {
                    id: 'ukuran',
                    title: 'Ukuran',
                    type: 'item',
                    url: '/master/ukuran/list'
                }
            ]
        },
        {
            id: 'merchandiser-core',
            title: 'Merchandiser',
            type: 'collapse',
            icon: icons.IconUser,
            roles: ['ADMIN'],
            children: [
                {
                    id: 'absensi',
                    title: 'Absensi',
                    type: 'item',
                    url: '/merchandiser/absensi/list'
                },
                {
                    id: 'kunjungan-outlet',
                    title: 'Kunjungan Outlet',
                    type: 'item',
                    url: '/merchandiser/kunjungan-outlet/list'
                }
            ]
        }
    ]
};

export default admin;
