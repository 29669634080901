import { getAuthProfile } from 'store/auth/auth.actions';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const profile = getAuthProfile();

    const navItems = menuItem.items
        .filter((item) => typeof item.roles === 'undefined' || item.roles.indexOf(profile.backend_access?.role) !== -1)
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
