// action - state management
import * as actionTypes from './types';

export const initialState = {
    dialog: {
        open: false,
        title: '',
        message: '',
        onOk: null,
        onCancel: null
    },
    fullScreenImageViewer: {
        open: false,
        index: 0,
        images: [],
        detailRoutePrefix: ''
    },
    search: {
        activeDataGrid: null,
        context: null,
        keyword: ''
    },
    searchDrawerOpen: false
};

// ==============================|| profile REDUCER ||============================== //

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_UI_DIALOG:
            return {
                ...state,
                dialog: action.payload
            };
        case actionTypes.SET_UI_FULL_SCREEN_IMAGE_VIEWER:
            return {
                ...state,
                fullScreenImageViewer: action.payload
            };
        case actionTypes.SET_UI_FULL_SCREEN_IMAGE_VIEWER_INDEX:
            return {
                ...state,
                fullScreenImageViewer: {
                    ...state.fullScreenImageViewer,
                    index: action.payload
                }
            };
        case actionTypes.SET_UI_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case actionTypes.SET_UI_SEARCH_KEYWORD:
            return {
                ...state,
                search: {
                    ...state.search,
                    keyword: action.payload
                }
            };
        case actionTypes.SET_UI_SEARCH_DRAWER_OPEN:
            return {
                ...state,
                searchDrawerOpen: action.payload
            };
        case actionTypes.SET_UI_SEARCH_DRAWER_OPEN_TOGGLE:
            return {
                ...state,
                searchDrawerOpen: !state.searchDrawerOpen
            };
        default:
            return state;
    }
};

export default uiReducer;
